@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/**************************/
/* CSS Animations Classes */
/**************************/

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;

  transition: opacity 250ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;

  transition: opacity 250ms ease-out;
}

.select input {
  font-family: 'Inter';
}